import React, { useRef, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import view from "./Events.module.scss"

export default function Events(props) {
    const { title, EventPage } = props.data


    return EventPage.length > 0 ?
        (
            <div id="event-container" className={view["event-container"]}>
                <div className={view["event-head"]}>
                    {title}
                </div>
                <div className={view["event-wrap"]}>
                    {
                        EventPage.map((e, idx) => (
                            <Link key={idx} to={e.link} className={view["event-link"]}>
                                <div className={view["event-img"]}>
                                    <img src={e.image}/>
                                </div>
                                <div className={view["event-cont"]}>
                                <h2> {e.date} </h2>
                                <p dangerouslySetInnerHTML={{ __html:  e.description}}>
                                </p>
                                </div> 
                            </Link>
                        ))
                    }
                </div>
            </div>
        ) : <div id="event-container" className={view["event-container"]}>
                <div className={view["event-head"]}>
                    {title}
                </div>
                <div className={view["event-none"]}>
                    No Upcoming Events. Check Back Soon.
                </div>
            </div>
}
