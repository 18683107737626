import React from "react"
import Layout from '../components/layout'
import SEO  from '../components/SEO'
import Events from '../components/events/Events'
import HTML from "../components/HTML"

function EventsPage(props) {
    const { pageContext } = props
    const { pageContent, theme,dealerInfo, headerTags} = pageContext
    const dealerHours = dealerInfo
    const dealerLoc = dealerInfo
    const document = typeof window !== 'undefined' && window.document
console.log(props)

    let components = [
        <Events  data={pageContent.EventPage }></Events>,
        <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} tagString={headerTags}/>
    ]

    if(pageContent.hasOwnProperty("ArrayRawHTML")) {
        const { arrayRawHTML } = pageContent.ArrayRawHTML
        const rawComponents = arrayRawHTML.map(element => {
            return <HTML data={{
                order: Number(element.order),
                visible: element.visible,
                rawHTML: element.rawHtml
            }} key={"HTML"} />
        })
        components.push(...rawComponents)
    }

    let sortedComponents = components.sort((a, b) =>
        parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
    ) // sorts the components based on json data
    // filters out the components that are not visible
    let visibleComponents = sortedComponents.filter(component => {
        return component.props.data.visible === true
    })

    return (
        <Layout>
            { visibleComponents}
        </Layout>
    )
}

export default EventsPage
